import { makeStyles } from '@admitkard/ccl';
import { HomePageConfigContext } from 'components/Home/HomeV2/contexts';
import Image from 'next/image';
import { useRouter } from 'next/router';
import React, { useEffect, useRef } from 'react';
import { env } from '@admitkard/uiutils/env';

const useStyles = makeStyles(
  (theme) => ({
    root: {
      width: '100%',
      minHeight: '60vh',
      position: 'relative',
      boxSizing: 'border-box',
      display: 'grid',
      gridTemplateColumns: 'repeat(auto-fit,minmax(300px,1fr))',
      alignItems: 'center',
      fontFamily: 'Poppins',
      marginTop: '0.5rem',
      '& h1': {
        fontSize: '1.5rem',
        fontWeight: 400,
        color: '#483287',
        lineHeight: '2rem',
        margin: '0',
        '& span': {
          display: 'block',
        },
        '& p':{
          margin: '0px',
          padding: '0px',
          display:'inline-block',
        }
      },
      '@media screen and (min-width: 1200px)': {
        marginTop: '0',
      },
    },
    infoContainer: {
      display: 'flex',
      flexDirection: 'column',
      rowGap: '0.75rem',
      marginBottom: '0.5rem',
      '@media screen and (min-width: 1200px)': {
        rowGap: '3.75rem',
        marginBottom: '0',
      },
    },
    title: {
      width: '17rem',
      padding: '1rem 2rem 0rem 2rem ',
      order: 1,
      backgroundColor: '#FFFFFF',
      zIndex: -1,
      '@media screen and (min-width: 1200px)': {
        width: '29rem',
        '& h1': {
          fontSize: '2.5rem',
          lineHeight: '3rem',
        },
      },
    },
    infoCard: {
      width: 'fit-content',
      borderRadius: '1rem',
      backgroundColor: '#F7EFFF',
      display: 'flex',
      order: 1,
      padding: '0.75rem',
      marginLeft: '2rem',
      '@media screen and (min-width: 1200px)': {
        order: 2,
        padding: '1.875rem 1.25rem',
        borderRadius: '1.75rem',
      },
    },
    button: {
      width: '17rem',
      margin: '0rem 2rem',
      order: 1,
    },
    infoCardItemVDiv: {
      margin: '0 0.75rem',
      width: '0.0625rem',
      backgroundColor: 'hsla(0, 0%, 100%, 1)',
      '@media screen and (min-width: 1200px)': {
        margin: '0 1.25rem',
      },
    },
    infoCardItems: {
      display: 'flex',
      flexDirection: 'column',
      position: 'relative',
      '@media screen and (min-width: 1200px)': {
        rowGap: '6px',
      },
    },
    infoCount: {
      fontSize: '1.125rem',
      fontWeight: 300,
      color: '#483287',
      '@media screen and (min-width: 1200px)': {
        fontSize: '1.75rem',
      },
    },
    infoLabel: {
      fontSize: '0.75rem',
      fontWeight: 400,
      color: '#483287',
      lineHeight: '1rem',
      maxWidth: '4rem',
      '@media screen and (min-width: 1200px)': {
        fontSize: '1rem',
        maxWidth: 'unset',
      },
    },
    customImageContainer: {
      '--size' : '39.75rem',
      height: 'var(--size)',
      width: 'var(--size)',
      aspectRatio: '1/1',
      // move left
      position: 'relative',
      left: '-5.5rem',
      zIndex: -1,
      '& img': {
        height: 'var(--size)',
        width: 'var(--size)',
        aspectRatio: '1/1',
      },
      '@media screen and (max-width: 991px)': {
        '--size' : '25rem',
      },
      '@media screen and (max-width: 767px)': {
        '--size': '28rem',
        position: 'unset',
        left: 'unset',
        justifySelf: 'center',
      },
      '@media screen and (max-width: 575px)': {
        '--size': '25rem',
      },
      '@media screen and (max-width: 380px)': {
        '--size': '20rem',
      }
    },
    imageContainer: {
      height: '30rem',
      width: '27rem',
      top: '1.5rem',
      position: 'relative',
      fontSize: '0.8rem',
      marginRight: '-5rem',
      marginTop: '-2rem',
      zIndex: -1,
      '@media screen and (min-width: 1200px)': {
        height: '40.25rem',
        right: '-5rem',
        fontSize: '1.2rem',
        marginRight: '0rem',
        marginTop: '0rem',
        marginBottom: '3rem',
        justifySelf: 'right',
      },
    },
    background: {
      position: 'absolute',
      height: '100%',
      width: '180%',
      overflowX: 'visible',
      left: '-6.5rem',
    },
    circle: {
      position: 'absolute',
      borderRadius: '50%',
    },
    circleInner: {
      height: '22em',
      width: '22em',
      top: '7em',
      right: '2em',
      background: '#FFEFE0',
      overflow: 'hidden',
    },
    imageStudent: {
      width: '100%',
      position: 'absolute',
      aspectRatio: '1/1',
    },
    circleOuter1: {
      height: '25.5em',
      width: '25.5em',
      top: '5.25em',
      right: '0.25em',
      border: '1px solid #DEBDFF',
      animation: '$rowRow 100s linear infinite',
    },
    circleOuter2: {
      height: '32em',
      width: '32em',
      top: '2em',
      right: '-3em',
      border: '1px solid #DEBDFF',
      animation: '$rowRow 100s linear infinite',
    },
    circleOuter3: {
      height: '32em',
      width: '32em',
      top: '2em',
      right: '-3em',
      border: '1px solid #DEBDFF',
      clipPath: 'polygon(0 39%, 0% 100%, 24% 100%)',
      '&:after': {
        height: '32em',
        width: '32em',
        content: '""',
        position: 'absolute',
        borderRadius: '32rem',
        borderLeft: '1px solid #8D61E7',
        top: '0em',
        right: '0em',
        transform: 'rotate(327deg)',
      },
    },
    circleOuter4: {
      height: '7em',
      width: '7em',
      top: '7.5em',
      right: '24em',
      background: '#FFEFE0',
      overflow: 'hidden',
    },
    circleOuter5: {
      height: '120rem',
      width: '120rem',
      top: '-38rem',
      left: '-6.5rem',
      border: '1px solid #DEBDFF',
      clipPath: 'polygon(0% -200%, 0% 100%, 3.7% 100%)',
      '@media screen and (max-width: 1299px)': {
        display: 'none',
      },
    },
    imageMentor: {
      width: '100%',
      position: 'absolute',
      aspectRatio: '1/1',
    },
    dialogBox: {
      backgroundColor: '#FFFFFF',
      position: 'absolute',
      height: '2.6em',
      width: '9.3em',
      borderRadius: '0.4em',
      padding: '0rem .5em',
      border: '0.5px solid #D8D8D8',
      lineHeight: '0.85em',
      boxShadow: '0em 0em 2.5em rgba(0, 0, 0, 0.06)',
      '& p': {
        fontSize: '.75em',
        margin: '0.6em 0em',
      },
      '& span': {
        fontWeight: '500',
      },
    },
    dialogBox1: {
      top: '13em',
      right: '22em',
    },
    dialogBox2: {
      top: '29em',
      right: '15.5em',
    },
    dot: {
      height: '0.5em',
      width: '0.5em',
      background: '#8D61E7',
    },
    dot1: {
      zIndex: 10,
      top: '15.4em',
      right: '28.7em',
      '@media screen and (min-width: 1200px)': {
        top: '15.4em',
        right: '28.65em',
      },
    },
    dot2: {
      zIndex: 10,
      top: '28.8em',
      right: '24.6em',
      '@media screen and (min-width: 1200px)': {
        right: '24.5em',
      },
    },
    countryFlagCircle1: {
      height: '2em',
      width: '2em',
      top: '19em',
      right: '22.7em',
      overflow: 'hidden',
    },
    countryFlagCircle2: {
      height: '1.2em',
      width: '1.2em',
      top: '1.8em',
      right: '24em',
      overflow: 'hidden',
      backgroundImage:
        // eslint-disable-next-line max-len
        'url(https://upload.wikimedia.org/wikipedia/en/thumb/a/a4/Flag_of_the_United_States.svg/800px-Flag_of_the_United_States.svg.png?20151118161041)',
      backgroundSize: 'cover',
      animation: '$reverseRowRow 100s linear infinite',
    },
    countryFlagCircle3: {
      height: '0.9em',
      width: '0.9em',
      top: '3.5em',
      right: '3em',
      overflow: 'hidden',
      backgroundImage:
        // eslint-disable-next-line max-len
        'url(https://upload.wikimedia.org/wikipedia/en/thumb/a/ae/Flag_of_the_United_Kingdom.svg/800px-Flag_of_the_United_Kingdom.svg.png?20151118161041)',
      backgroundSize: 'cover',
      animation: '$reverseRowRow 100s linear infinite',
    },
    countryFlagCircle4: {
      height: '1.1em',
      width: '1.1em',
      top: '23.4em',
      right: '6em',
      overflow: 'hidden',
      backgroundImage:
        // eslint-disable-next-line max-len
        'url(https://upload.wikimedia.org/wikipedia/en/thumb/c/c3/Flag_of_France.svg/300px-Flag_of_France.svg.png?20151118161041)',
      backgroundSize: 'contain',
      animation: '$reverseRowRow 100s linear infinite',
    },
    countryFlagCircle5: {
      height: '1.1em',
      width: '1.1em',
      top: '38rem',
      left: '-4.9rem',
      overflow: 'hidden',
      backgroundImage:
        // eslint-disable-next-line max-len
        'url(https://upload.wikimedia.org/wikipedia/en/thumb/c/c3/Flag_of_France.svg/300px-Flag_of_France.svg.png?20151118161041)',
      backgroundSize: 'contain',
      '@media screen and (max-width: 1299px)': {
        display: 'none',
      },
    },
    cardButtonContainer: {
      height: '3rem',
      width: 'max-content',
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      padding: '1rem 1rem 1rem 1.25rem',
      gap: '0.75rem',
      background: theme.colorPalette.indigo.main,
      borderRadius: '1.875rem',
      boxSizing: 'border-box',
      fontSize: '0.875rem',
      fontFamily: 'Poppins',
      fontStyle: 'normal',
      lineHeight: '1rem',
      fontWeight: 400,
      color: '#F7EFFF',
      border: 'none',
      textDecoration: 'none',
      '&:hover': {
        transform: 'translateY(-0.2rem)',
        boxShadow: '0px 3px 0px rgb(0 0 0 / 25%)',
      },
      '@media screen and (min-width: 992px)': {
        fontSize: '1.1rem',
        height: '3.5rem',
      },
    },
    cardButtonIconContainer: {
      height: '1.25rem',
      aspectRatio: '1',
      '@media screen and (min-width: 992px)': {
        height: '1.5rem',
      },
      '& svg': {
        height: 'inherit',
        width: 'inherit',
      },
    },
    hideWithDisplay: {
      display: 'none',
    },
    '@keyframes spin': {
      // eslint-disable-next-line @typescript-eslint/naming-convention
      '100%': { transform: 'rotate(360deg)' },
    },
    '@keyframes reverseSpin': {
      // eslint-disable-next-line @typescript-eslint/naming-convention
      '100%': { transform: 'rotate(-360deg)' },
    },
    '@keyframes rowRow': {
      // eslint-disable-next-line @typescript-eslint/naming-convention
      '0%': { transform: 'rotate(0deg)' },
      // eslint-disable-next-line @typescript-eslint/naming-convention
      '50%': { transform: 'rotate(180deg)' },
      // eslint-disable-next-line @typescript-eslint/naming-convention
      '100%': { transform: 'rotate(0deg)' },
    },
    '@keyframes reverseRowRow': {
      // eslint-disable-next-line @typescript-eslint/naming-convention
      '0%': { transform: 'rotate(0deg)' },
      // eslint-disable-next-line @typescript-eslint/naming-convention
      '50%': { transform: 'rotate(-180deg)' },
      // eslint-disable-next-line @typescript-eslint/naming-convention
      '100%': { transform: 'rotate(0deg)' },
    },
  }),
  { name: 'FirstFold' }
);

interface HeaderSectionProps {
  externalData?: Record<string, any>,
  shouldAttachQueryParams?: boolean,
}

function buildHighlightedText(text: string) {
  const regex = /(\*.*?\*)/g;
  const result = [];

  let match;
  let lastIndex = 0;

  while ((match = regex.exec(text)) !== null) {
    const startIndex = match.index;
    const endIndex = regex.lastIndex;

    const normalText = text.substring(lastIndex, startIndex);
    const highlightedText = match[1].replace(/\*/g, '');

    if (normalText) {
      result.push(normalText);
    }

    result.push(<p key={startIndex} style={{color: '#B777FA'}}>{highlightedText}</p>);
    lastIndex = endIndex;
  }

  const remainingText = text.substring(lastIndex);
  if (remainingText) {
    result.push(remainingText);
  }

  return <>{result}</>;
}




function buildSpans(message: string) {
  return message.split('\n').map((value,index) => <span key={`msg-span-${index}`}>{buildHighlightedText(value)}</span>);
}

const FirstFold: React.FunctionComponent<HeaderSectionProps> = (props) => {
  const classes = useStyles();

  const homePageConfig = React.useContext(HomePageConfigContext);
  const hasCustomBanner = homePageConfig?.headerIllustration?.imageUrl !== undefined;

  const vDivider = <div className={classes.infoCardItemVDiv} />;
  const redirectionURL = homePageConfig.primaryCTA?.redirectionURL ?? '/paf';
  const redirectionUrlRef = useRef<string>(redirectionURL);

  const nextRouter = useRouter();

  const primaryMessage = 'study abroad \n with expert \n guidance from \n *international* students';
  const messageComponent = buildSpans(homePageConfig.primaryMessage ?? primaryMessage);

  function buildURLString(queryParams: any): string {
    const rUrl = new URL('/', `https:/${redirectionURL}`);

    if(Object.keys(queryParams).length>0) {
      Object.keys(queryParams).map((key) => rUrl.searchParams.set(key, queryParams[key]));
    }

    return rUrl.toString().replace('https://', '/');
  }
  function parsePathQuery(pathWithParams: string) {
    const url = new URL(pathWithParams);
    const params = new URLSearchParams(url.search);
    const obj: { [key: string]: string } = {}; // Add index signature to allow indexing with a string
    for (const [key, value] of params.entries()) {
      obj[key] = value;
    }
    return obj;
  }

  function getQueryForNext() {
    const currentPath =  env.UI_URL + nextRouter.asPath ;

    if(currentPath) {
      if(currentPath.includes('/?')) {
        return parsePathQuery(currentPath);
      }
    }

    return undefined;
  }

  useEffect(() => {
    const queryParams = props.externalData?.incomingQueryParams ?? getQueryForNext();

    const shouldAttachQueryParams = ((props.shouldAttachQueryParams === true || homePageConfig.shouldAttachQueryParams === true)
     && (queryParams !== undefined));

    if(shouldAttachQueryParams) {
      const validQueryParams = { ...queryParams };

      // eslint-disable-next-line spellcheck/spell-checker
      /**
       * This code block is checking for a condition where shouldRemoveUTMTags value is false, and if it is false,
       * it is removing any query parameters that starts with 'utm_' from validQueryParams object using delete method.
       * This code is using Object.keys method to get all the keys of the object and forEach method to iterate through those keys.
       */
      if(!homePageConfig.shouldKeepUTMTags) {
        Object.keys(validQueryParams).forEach((key) => {
          if(key.startsWith('utm_')) {
            delete validQueryParams[key];
          }
        });
      }

      redirectionUrlRef.current = buildURLString(validQueryParams);
    }
  },[]);

  return (
    <div id='header-section' className={classes.root}>
      <div className={classes.infoContainer}>
        <div className={classes.title}>
          <h1>
            { messageComponent }
          </h1>
        </div>
        <div className={classes.infoCard}>
          {renderInfoCardItem({
            count: '4K',
            label: 'universities',
          })}
          {vDivider}
          {renderInfoCardItem({
            count: '3K',
            label: 'mentors',
          })}
          {vDivider}
          {renderInfoCardItem({
            count: '40',
            label: 'countries',
          })}
          {vDivider}
          {renderInfoCardItem({
            count: '90K+',
            label: 'success stories',
          })}
        </div>
        <div className={classes.button}>
          <a id="cta-button" href={redirectionUrlRef.current} className={classes.cardButtonContainer}>
            {homePageConfig.primaryCTA.CTALabel}
            <span className={classes.cardButtonIconContainer}>
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect width="24" height="24" rx="12" fill="#F7EFFF" />
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M12.8425 11.8281C12.9207 11.75 12.9207 11.6234 12.8425 11.5453L9.69108 8.39379C9.49582 8.19853 9.49582 7.88195 9.69108 7.68668C9.88634 7.49142 10.2029 7.49142 10.3982 7.68668L14.0446 11.3331C14.2399 11.5284 14.2399 11.845 14.0446 12.0402L10.3982 15.6867C10.2029 15.8819 9.88634 15.8819 9.69108 15.6867C9.49582 15.4914 9.49581 15.1748 9.69108 14.9796L12.8425 11.8281Z"
                  fill="#483287"
                />
              </svg>
            </span>
          </a>
        </div>
      </div>
      <div className={`${classes.customImageContainer} ${hasCustomBanner ? '' : classes.hideWithDisplay}`}>
        <picture>
          <img src={homePageConfig?.headerIllustration?.imageUrl} alt='banner-image' />
        </picture>
      </div>
      <div className={`${classes.imageContainer} ${hasCustomBanner ? classes.hideWithDisplay : ''}`}>
        <div className={classes.background}>
          <Image src="https://assets.admitkard.com/homepage_background.webp" layout="fill" priority></Image>
        </div>
        <div className={`${classes.circle} ${classes.circleInner}`}>
          <div className={classes.imageStudent}>
            <Image src="https://assets.admitkard.com/images/student_model.webp" layout="fill" priority></Image>
          </div>
        </div>
        <div className={`${classes.circle} ${classes.circleOuter1}`}>
          <div className={`${classes.circle} ${classes.countryFlagCircle3}`} />
          <div className={`${classes.circle} ${classes.countryFlagCircle4}`} />
        </div>
        <div className={`${classes.circle} ${classes.circleOuter2}`}>
          <div className={`${classes.circle} ${classes.countryFlagCircle2}`} />
        </div>
        <div className={`${classes.circle} ${classes.circleOuter3}`} />
        <div className={`${classes.circle} ${classes.circleOuter4}`}>
          <div className={classes.imageMentor}>
            <Image src="https://assets.admitkard.com/images/mentor_model.webp" layout="fill" priority></Image>
          </div>
        </div>
        <div className={`${classes.circle} ${classes.countryFlagCircle1}`}>
          <Image src="https://assets.admitkard.com/images/Flag_of_India.webp" layout="fill" priority></Image>
        </div>
        <div className={`${classes.circle} ${classes.dot} ${classes.dot1}`} />
        <div className={`${classes.circle} ${classes.dot} ${classes.dot2}`} />
        <div className={`${classes.dialogBox} ${classes.dialogBox1}`}>
          <p>
            <span>Clarke</span> is an alumni from Oxford University
          </p>
        </div>
        <div className={`${classes.dialogBox} ${classes.dialogBox2}`}>
          <p>
            <span>Arnav</span> looking forword to join Oxford University
          </p>
        </div>
      </div>
      <div className={`${classes.circle} ${classes.circleOuter5} ${hasCustomBanner ? classes.hideWithDisplay : ''}`} />
      <div className={`${classes.circle} ${classes.countryFlagCircle5} ${hasCustomBanner ? classes.hideWithDisplay : ''}`} />
    </div>
  );

  function renderInfoCardItem(params: { count: string; label: string }) {
    return (
      <div className={classes.infoCardItems}>
        <span className={classes.infoCount}>{params.count}</span>
        <span className={classes.infoLabel}>{params.label}</span>
      </div>
    );
  }
};

export default FirstFold;
